import { Print, LocationOn } from '@mui/icons-material';
import {
	Card,
	CardContent,
	CardHeader,
	Chip,
	ButtonBase as OrgButtonBase,
	Stack,
	Theme,
	useTheme
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DefinitionList } from '../../../mui-custom/DefinitionList';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAccountDeactivatedCheck } from '../../../hooks/useAccountDeactivatedCheck';
import { dateTime } from '../../../lib/dateHelper';
import Page from '../../../mui-custom/Page/Page';
import { State } from '../../reducers';
import { IconicText } from '../../../components/IconicText';
import { CasaviCommunity } from '@api/v1';
import { AttachmentList, AttachmentThumbnail } from '../../../components/Attachment';
import { mapTicketStatusToColor } from '../../../lib/types';

const ButtonBase = (props: { [x: string]: unknown }) => {
	const { accountDeactivated } = useAccountDeactivatedCheck();
	if (!accountDeactivated) return <OrgButtonBase {...props} />;
	return <OrgButtonBase {...props} disabled />;
};

const useStyles = makeStyles(({ spacing }: Theme) => ({
	headerButton: {
		padding: spacing(1)
	},
	iconText: {
		marginTop: spacing(0.5),
		marginBottom: spacing(0.5)
	}
}));
export function DetailSubPage() {
	const { t } = useTranslation();
	const classes = useStyles();
	const { ticketDetails } = useSelector((state: State) => state.tickets);
	const theme = useTheme();

	const mapStatusToLabel = {
		OPEN: t('tickets:status_open').toUpperCase(),
		INPROGRESS: t('tickets:status_inprogress').toUpperCase(),
		POSTPONED: t('tickets:status_postponed').toUpperCase(),
		CLOSED: t('tickets:status_closed').toUpperCase()
	};

	const getCasaviAddress = (community: CasaviCommunity) => {
		if (!community) return '';
		let addressString = '';
		if (community.Addresses.length > 0) {
			community.Addresses.forEach((address) => {
				addressString += `${address.street} `;
			});
		}
		addressString += `${community.postalCode} ${community.city} ${community.country}`;

		return addressString;
	};

	return (
		<Page
			id="ticket_details_container"
			headline={
				<Stack direction="row" sx={{ justifyContent: 'space-between' }}>
					<Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
						<span>{`${ticketDetails.title} #${ticketDetails.id}`}</span>
						<Chip
							color={mapTicketStatusToColor[ticketDetails.status]}
							size="small"
							sx={{ borderRadius: '4px', minWidth: theme.spacing(11) }}
							label={mapStatusToLabel[ticketDetails.status] ?? ticketDetails.status}
						/>
					</Stack>
					<ButtonBase onClick={() => window.print()} className={classes.headerButton}>
						<Print />
					</ButtonBase>
				</Stack>
			}
			text={`${t('tickets:detail_page_created_at_label')}: ${dateTime(
				ticketDetails.createdAt
			).toFormat('dd.MM.yyyy HH:mm')}`}
		>
			<Card>
				<CardHeader title={t('tickets:label_property')} />
				<DefinitionList
					sx={{ px: 2 }}
					items={[
						{
							key: t('tickets:label_property_manager'),
							value: ticketDetails.Tenant?.name
						},
						{
							key: t('tickets:label_property'),
							value: ticketDetails.Community?.name
						},
						{
							key: t('tickets:label_property_address'),
							value: (
								<IconicText
									className={classes.iconText}
									icon={<LocationOn />}
									text={
										<a
											href={`https://maps.google.com/?q=${getCasaviAddress(
												ticketDetails.Community
											)}`}
											target="_blank"
											dangerouslySetInnerHTML={{
												__html: getCasaviAddress(ticketDetails.Community)
											}}
										></a>
									}
								/>
							)
						}
					]}
				/>
			</Card>
			{ticketDetails.Attachments?.length > 0 && (
				<Card>
					<CardHeader title={t('tickets:detail_page_attachments')} />
					<CardContent>
						<AttachmentList>
							{(ticketDetails.Attachments || [{}]).map((attachment) => (
								<AttachmentThumbnail
									key={attachment.id}
									name={attachment.name}
									link={attachment.secureUrl}
									thumbnail={attachment.thumbS3key}
								/>
							))}
						</AttachmentList>
					</CardContent>
				</Card>
			)}
		</Page>
	);
}
