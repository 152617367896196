import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { AdminExclusivePermission } from '../../server/permissions/permission';
import { LoadingScreen } from '../components/LoadingScreen';
import { ConfirmEmailBanner, InTrialBanner, PricingV2InfoBanner, SysAdminBanner } from './Banner';
import { AccountSpecificFeature, useAccountSpecificFeature } from './acl/features';
import { usePermission } from './acl/permissions';
import { loadAccountDetails } from './admin/account/actions';
import { checkToken, getAccount } from './auth/actions';
import { initializeStore, sagaMiddleware } from './createStore';
import { fetchCustomerStatistics } from './customers/actions';
import { NavbarApp } from './navbar/App';
import { fetchOrderStatistics } from './orders/actions';
import { Paywall } from './paywall/App';
import { fetchPropertiesStatistics } from './properties/actions';
import { State, reducers } from './reducers';
import { rootSaga } from './sagas';
import { SidebarApp } from './sidebar/App';
import { SideRoute } from './sidebar/Routes';
import { ChargebeeSubscriptionStatus } from '../../server/chargebee/chargebee.enums';
initializeStore({}, reducers);

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		width: '100%',
		height: '100%',
		flexDirection: 'column',
		'@media print': {
			height: 'unset',
			display: 'block'
		}
	},
	content: {
		display: 'flex',
		width: '100%',
		height: '100%',
		overflowY: 'auto',
		[theme.breakpoints.up('md')]: {
			height: '100%'
		},
		'@media print': {
			display: 'block'
		}
	},
	layoutFix: {
		display: 'flex',
		minWidth: 0,
		width: '100%',
		height: '100%',
		'@media print': {
			display: 'block'
		},
		overflow: 'auto'
	}
}));

export function General(props: {}) {
	const { user, account } = useSelector((state: State) => state.auth);

	const isSysadmin = !!window.localStorage.getItem('sysadmin_logged_in_as_user');
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const classes = useStyles(props);
	const hasAdminView = usePermission(AdminExclusivePermission.VIEW_ADMIN);
	sagaMiddleware.run(rootSaga);
	const showPricingV2InfoBanner = useAccountSpecificFeature(
		AccountSpecificFeature.ShowPricingV2InfoBanner
	);

	useEffect(() => {
		dispatch(
			checkToken((err, data) => {
				if (err || !_.isObject(data)) {
					history.replace('/login', {
						nextPath: location.pathname + location.search
					});
				} else {
					dispatch(getAccount());
					dispatch(fetchOrderStatistics());
					dispatch(fetchCustomerStatistics());
					dispatch(fetchPropertiesStatistics());
				}
			})
		);
	}, []);

	useEffect(() => {
		if (hasAdminView) dispatch(loadAccountDetails());
	}, [hasAdminView]);

	if (!user) return <LoadingScreen />;

	return (
		<>
			<Paywall />
			<div className={classes.root}>
				{!user?.emailConfirmed && <ConfirmEmailBanner email={user?.email} />}
				{account?.subscriptionStatus === ChargebeeSubscriptionStatus.IN_TRIAL && (
					<InTrialBanner activePlanUntil={account?.activePlanUntil} />
				)}
				{isSysadmin && <SysAdminBanner />}
				{showPricingV2InfoBanner && <PricingV2InfoBanner />}
				<NavbarApp hasBanner={!user?.emailConfirmed || isSysadmin} />
				<div className={classes.content}>
					<SidebarApp />
					<div className={classes.layoutFix} id="layoutFix">
						<SideRoute hasAdminView={hasAdminView} />
					</div>
				</div>
				<div id="common_data_field" data-account-id={user.accountId} />
			</div>
		</>
	);
}
