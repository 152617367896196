import { Chat, Description } from '@mui/icons-material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Stack, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { LoadingScreen } from '../../../components/LoadingScreen';
import { useMobile } from '../../../hooks/useBreakpoints';
import ActionBar from '../../../mui-custom/ActionBar/ActionBar';
import ListOrTabNav from '../../../mui-custom/ListOrTabNav/ListOrTabNav';
import { State } from '../../reducers';
import { loadTicketDetails } from '../actions';
import { DetailSubPage } from './DetailSubPage';
import { CommentCompose } from './CommentCompose';

enum MOBILE {
	DETAIL,
	CHAT
}
const useStyles = makeStyles(({ spacing }: Theme) => ({
	wrapper: {
		width: '100%',
		height: '100%'
	},
	container: {
		marginTop: spacing(-3),
		width: '100%',
		height: `calc(100% + ${spacing(3)})`,
		display: 'flex',
		flexDirection: 'column',
		'@media print': {
			display: 'block'
		}
	},
	details: {
		margin: spacing(1),
		marginTop: spacing(4),
		overflow: 'unset',
		'@media print': {
			margin: 0,
			boxShadow: 'none',
			pageBreakAfter: 'always'
		},
		paddingBottom: spacing(1)
	},
	statusBlock: {
		padding: spacing(3)
	},
	separateLine: {
		height: spacing(1),
		border: 0,
		margin: 0
	},
	hideOnPrint: {
		'@media print': {
			display: 'none'
		}
	},
	fullWidthOnPrint: {
		'@media print': {
			flexDirection: 'column',
			margin: 0
		}
	}
}));
export function TicketDetailView() {
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const { t } = useTranslation();
	const classes = useStyles();
	const history = useHistory();
	const { ticketDetails } = useSelector((state: State) => state.tickets);
	const [mobileActive, setMobileActive] = useState(0);
	const isMobile = useMobile();

	useEffect(() => {
		const ticketId = Number(pathname.split('/').pop());
		dispatch(loadTicketDetails(ticketId));
	}, []);

	if (ticketDetails?.isLoading) {
		return <LoadingScreen />;
	}

	const backToList = () => history.push({ pathname: '/app/tickets' });

	return (
		<div className={classes.wrapper}>
			<ActionBar
				alternate={{
					onClick: backToList,
					children: <ArrowBack />
				}}
			>
				<Typography variant="h6">{t('tickets:ticket_details_title')}</Typography>
			</ActionBar>
			{isMobile && (
				<ListOrTabNav
					variant="tab"
					navItems={[
						{ icon: Description, label: t('tickets:detail_page_mobile_nav_ticket') },
						{ icon: Chat, label: t('tickets:detail_page_mobile_nav_chat') }
					]}
					setActiveId={setMobileActive}
					activeId={mobileActive}
					className={classes.hideOnPrint}
				/>
			)}
			<Stack
				direction="row"
				sx={isMobile ? {} : { m: 2, px: 2, gap: 2 }}
				className={classes.fullWidthOnPrint}
			>
				{(!isMobile || mobileActive === MOBILE.DETAIL) && (
					<Stack sx={{ flex: '1 1 67%' }}>
						{/* // use Grid for DetailSubPage here */}
						<DetailSubPage />
						{/* // ChatComponent here */}
					</Stack>
				)}
				{(!isMobile || mobileActive === MOBILE.CHAT) && (
					<Stack sx={{ flex: '1 1 67%' }}>
						{/* // use Grid for DetailSubPage here */}
						<CommentCompose sx={{ width: 'unset' }} />
						{/* // ChatComponent here */}
					</Stack>
				)}
			</Stack>
		</div>
	);
}
